export default [
    {
        id: "digitalisieren",
        label: "Digitalisierungen",
        text: "VHS, Super 8, 16mm oder ein altes Bild? Wir digitalisieren Ihre Erinnerungen wie Videos und Bilder für Sie. Auch Großaufträge wie Unternehmensarchive!",
        route: "/content/digitalisieren",
        img_url: "./img/frontpage/Videokassetten.jpg",
        showInTitle: true
    },
    {
        id: "einrahmen",
        label: "Bilder einrahmen",
        text: "Rahmen für alle Anlässe. Wir drucken Ihre Bilder und rahmen diese in eine Vielzahl von Formaten ein. Mit Passepartout oder ohne... ",
        route: "/content/einrahmen",
        img_url: "./img/frontpage/RahmenSet.jpg",
        showInTitle: false
    },
    {
        id: "passbilder",
        label: "(Biometrische) Passbilder",
        text: 'Passbilder für Führerschein, Reisepass, Personalausweis oder ein internationales Visum? Wir erstellen mit Ihnen zusammen alle Arten von offiziellen Fotos. Auf Wunsch auch teils biometrisch, teils Standard auf einem Ausdruck...',
        route: "/content/passbilder",
        img_url: "./img/frontpage/Passbild4erBio.jpg",
        showInTitle: true
    },
    {
        id: "bewerbungsbilder",
        label: "Bewerbungsbilder",
        text: "Ansprechende, professionelle Bewerbungsbilder. Aufnahmen in unserem eigenem Studio!",
        route: "",
        img_url: "./img/Bewerbungsbilder/bewerbungsbild.jpg",
        showInTitle: false
    },
    {
        id: "bearbeitungColorierung",
        label: "Bearbeitung und Colorierung",
        text: "Ihre Bilder benötigen noch einen kleinen Feinschliff, oder Sie möchten Ihre Erinnerungen nachcolorieren lassen? Wir arbeiten mit den aktuellsten Techniken, um Ihre Bilder zu veredeln...",
        route: "",
        img_url: "./img/frontpage/Colorierung1.jpg",
        showInTitle: false
    },
    {
        id: "fotobuecher",
        label: "Fotobücher",
        text: "Ihre Erinnerungen als hochwertiges Fotobuch. Mit Gestaltungsservice oder ohne...",
        route: "",
        img_url: "./img/fotobuecher/fotobuch.jpg",
        showInTitle: false
    },
    {
        id: "industriefotografie",
        label: "Industriefotografie",
        text: "Großmaschine, Elektronik oder ganze Produktionsstraße. Wir lichten Ihre Produkte oder Produktionen professionell ab!",
        route: "",
        img_url: "./img/frontpage/Industrie1.jpg",
        showInTitle: false
    },
    {
        id: "mehr",
        label: "weitere Dienstleistungen",
        text: "Wir bieten noch weitere Dienstleistungen an! Entdecken Sie diese hier!",
        route: "",
        img_url: "./img/more/more.jpg",
        showInTitle: false
    }
]
