<template>
  <div>
    <v-parallax
      height="100"
      dark
      src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
    >
      <div>
        <v-row align="center" justify="center">
          <router-link class="back" to="/"
            ><v-btn x-large icon
              ><v-icon color="white">mdi-arrow-left</v-icon></v-btn
            ></router-link
          >
          <v-col class="text-center" cols="12">
            <div class="text-h5 font-weight-thin">
              {{ content.title }}
            </div>
          </v-col>
        </v-row>
      </div>
    </v-parallax>

    <div v-if="!componentMapping[$route.params.name]">
      <div
        v-for="(entry, index) in content.content"
        :key="entry.title"
        class="list"
      >
        <v-row v-if="index % 2 === 1" align="center">
          <v-col>
            <v-card class="card" elevation="0">{{ entry.text }}</v-card>
          </v-col>
          <v-col>
            <v-card class="card" elevation="0"
              ><v-img contain max-height="400px" :src="entry.url"></v-img
            ></v-card>
          </v-col>
        </v-row>

        <v-row v-else align="center">
          <v-col>
            <v-card class="card" elevation="0"
              ><v-img contain max-height="400px" :src="entry.url"></v-img
            ></v-card>
          </v-col>
          <v-col class="text-col">
            <v-card class="card" elevation="0">{{ entry.text }}</v-card>
          </v-col>
        </v-row>
      </div>
    </div>
    <component class="custom-template" v-else :is="componentMapping[$route.params.name]"></component>
  </div>
</template>
$emit('toggleDialog', 'dialog-contact-formular')
<script>
import ContentData from "../../data/content";
import ComponentMapping from "./ComponentMapping";

export default {
  data() {
    return {
      content: ContentData[this.$route.params.name],
      componentMapping:ComponentMapping
    };
  },
  methods: {},
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.list {
  padding-top: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 2rem;
}

.card {
  text-align: center;
}

.parallax {
  margin-bottom: 50px;
}

.text-line {
  margin-bottom: unset !important;
}

.back {
  padding-top: 0.5rem;
}

.divider {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.custom-template
{
  margin: 2rem;
}
</style>