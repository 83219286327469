<template>
  <div>
    <h1 class="header">Unsere Dienstleistungen</h1>
    <service-list class="service-list"></service-list>
  </div>
</template>

<script>
import ServiceList from "./ServiceList.vue";

export default {
  components: { ServiceList },
};
</script>

<style>

.header {
  font-size: xx-large;
  color: black;
  text-align: center;
  font-weight: lighter;
}

.service-list{
  margin-top: 2rem;
}
</style>