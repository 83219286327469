<template>
  <div class="menu">
    <router-link :to="link.route" v-for="link in links" :key="link.route">
      <v-btn class="button" tile elevation="0" >{{ link.label }}</v-btn>
    </router-link>
  </div>
</template>

<script>
import Services from '../../data/services'

export default {
  data() {
    return {
    };
  },
  computed:{
    links(){
      return Services.filter(service => service.showInTitle === true)
    }
  }
};
</script>

<style scoped>
template {
  height: 50px;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #f5f5f5;
}

.button{
}
</style>