export default {
    passbilder: {
        title: "Passbilder",
        content: [{ url: "", text: "" }],

    },
    digitalisieren: {
        title: "Digitalisieren", content: [{ url: "", text: "" }]
    },
    bewerbungsbilder: { title: "Bewerbungsbilder", content: [{ url: './img/bewerbungsbilder/Bewerbungsbild_1.jpg', text: "blubb" }, { url: './img/bewerbungsbilder/Bewerbungsbild_1.jpg', text: "blubb" }, { url: './img/bewerbungsbilder/Bewerbungsbild_1.jpg', text: "blubbblubbblubbblubb blubbblubbblub bblubbbl ubbblubbblubb blubbblubb" }, { url: './img/bewerbungsbilder/Bewerbungsbild_1.jpg', text: "blubb" }] },
    fotobuecher: { title: "Fotobücher", content: [{ url: "", text: "" }] },
    industriefotografie: { title: "Industriefotografie", content: [{ url: "", text: "" }] },
    einrahmen: { title: "Bilder einrahmen", content: [{ url: "", text: "" }] },
    bearbeitungColorierung: { title: "Bearbeitung und Colorierung", content: [{ url: "", text: "" }] },
    mehr: { title: "Weitere Services", content: [{ url: "", text: "" }] }
}