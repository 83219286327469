import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import ContactMap from '../views/ContactMap.vue'
import Impress from '../views/Impress.vue'
import Error404 from '../components/error/404'

import ContentTemplate from '../views/content/ContentTemplate'

import FormDigitalisierung from '../views/forms/FormDigitalisierung'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '*',
    name: '404',
    component: Error404
  },
  {
    path: '/contactmap',
    name: 'Anfahrt & Kontakt',
    component: ContactMap
  },
  {
    path: '/impress',
    name: 'Impressum',
    component: Impress
  },
  { path: '/content/:name', component: ContentTemplate },
  {
    path: '/forms/digitalisierung',
    name: 'Digitalisierung anfragen',
    component: FormDigitalisierung
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})



export default router
