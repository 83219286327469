<template>
  <v-app>
    <app-bar
      @toggleDialog="toggleDialog"
      @toggleDrawer="drawer = !drawer"
    ></app-bar>
    <v-main app>
      <router-view @toggleDialog="toggleDialog" />
    </v-main>

    <custom-footer></custom-footer>
    <!-- Dialog -->
    <v-dialog v-model="dialog" width="500"
      ><component :is="currentDialog" @toggleDialog="toggleDialog"></component
    ></v-dialog>
    <!--  -->
    <!-- Drawer -->
    <v-navigation-drawer width="70" v-model="drawer" app temporary fixed right>
      <drawer-list
        @toggleDialog="toggleDialog"
        @toggleDrawer="drawer = !drawer"
      ></drawer-list>
    </v-navigation-drawer>
    <!--  -->
  </v-app>
</template>

<script>
import CustomFooter from "./components/footer/CustomFooter.vue";
import AppBar from "./components/appbar/AppBar.vue";
import DrawerList from "./components/nav/DrawerList.vue";

// Dialogs
import DialogOpeningHours from "./components/dialogs/DialogOpeningHours.vue";
import DialogTelephone from "./components/dialogs/DialogTelephone.vue";
import DialogContactFormular from "./components/dialogs/DialogContactFormular.vue";
import DialogWhatsapp from "./components/dialogs/DialogWhatsapp.vue";

export default {
  name: "App",
  components: {
    AppBar,
    CustomFooter,
    DialogContactFormular,
    DialogWhatsapp,
    DialogTelephone,
    DialogOpeningHours,
    DrawerList,
  },
  data: () => ({
    drawer: false,
    dialog: false,
    currentDialog: "",
  }),
  methods: {
    toggleDialog(dialog) {
      if (!dialog) {
        this.dialog = !this.dialog;
      } else {
        this.currentDialog = dialog;
        this.drawer = false;
        this.dialog = true;
      }
    },
  },
};
</script>
<style>
.is-centered{
  text-align: center;
}

.request {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.router-link-active {
  text-decoration-line: none;
}

a {
  text-decoration-line: none;
}

.dialog-title {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.back {
  position: absolute;
  left: 16px;
  top: 16px;
}

.text-color-dark {
  color: rgba(0, 0, 0, 0.87);
}

.text-red {
  color: #b00020;
}

.top-buttons {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-right: 0.3rem;
  text-align: right;
}
</style>
