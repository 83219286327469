<template>
  <v-list nav dense>
    <v-list-item>
      <v-btn icon @click="$emit('toggleDrawer')"><v-icon secondary>mdi-close</v-icon></v-btn>
    </v-list-item>
    <v-list-item>
      <v-btn
        icon
        elevation="0"
        @click="$emit('toggleDialog', 'dialog-opening-hours')"
        ><v-icon>mdi-clock</v-icon></v-btn
      >
    </v-list-item>
    <v-list-item>
      <v-btn
        icon
        elevation="0"
        @click="$emit('toggleDialog', 'dialog-contact-formular')"
        ><v-icon>mdi-email</v-icon></v-btn
      >
    </v-list-item>
    <!-- <v-list-item>
      <v-btn
        icon
        elevation="0"
        @click="$emit('toggleDialog', 'dialog-whatsapp')"
        ><v-icon>mdi-whatsapp</v-icon></v-btn
      >
    </v-list-item> -->
    <v-list-item>
      <v-btn
        icon
        elevation="0"
        @click="$emit('toggleDialog', 'dialog-telephone')"
        ><v-icon>mdi-phone</v-icon></v-btn
      >
    </v-list-item>
    <v-list-item>
      <v-btn @click="openMap" icon
          ><v-icon>mdi-google-maps</v-icon></v-btn
        >
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  methods:{
     openMap() {
      this.$router.replace("/contactmap");
    }
  }
}
</script>