var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"height":"80px","app":"","color":"secondary"}},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{attrs:{"contain":"","max-width":"17rem","src":require('../../assets/logos/logo.png')}})],1),_c('v-spacer'),_c('div',{staticClass:"icons-hidden-small"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","elevation":"0"},on:{"click":function($event){return _vm.$emit('toggleDialog', 'dialog-opening-hours')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clock")])],1)]}}])},[_c('span',[_vm._v("Öffnungszeiten")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","elevation":"0"},on:{"click":function($event){return _vm.$emit('toggleDialog', 'dialog-contact-formular')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-email")])],1)]}}])},[_c('span',[_vm._v("Nachricht senden")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","elevation":"0"},on:{"click":function($event){return _vm.$emit('toggleDialog', 'dialog-telephone')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-phone")])],1)]}}])},[_c('span',[_vm._v("Rufen Sie an!")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.openMap}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-google-maps")])],1)]}}])},[_c('span',[_vm._v("Anfahrt")])])],1),_c('v-app-bar-nav-icon',{staticClass:"nav-icon",on:{"click":function($event){return _vm.$emit('toggleDrawer')}}})],1),_c('v-btn',{staticClass:"fab tel",attrs:{"href":"tel:0049704215777","elevation":"2","color":"primary","fab":""}},[_c('v-icon',{attrs:{"large":"","color":"white"}},[_vm._v("mdi-phone")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }