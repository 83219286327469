<template>
  <div class="error_404">
    <div class="text-h3 font-weight-thin">Nicht gefunden :(</div>
    <div class="text-subtitle font-weight-light">
      Vielleicht war das ein alter Link!
    </div>
    <div class="text-subtitle font-weight-light">
      <router-link to="/">Zurück zur Übersicht</router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.error_404 {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}
</style>