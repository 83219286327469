<template>
  <div>
    <v-parallax
      height="100"
      dark
      src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
    >
      <div>
        <v-row align="center" justify="center">
          <router-link class="back" to="/content/digitalisieren"
            ><v-btn x-large icon
              ><v-icon color="white">mdi-arrow-left</v-icon></v-btn
            ></router-link
          >
          <v-col class="text-center" cols="12">
            <div class="text-h5 font-weight-thin">Digitalisierung anfragen</div>
          </v-col>
        </v-row>
      </div>
    </v-parallax>

    <v-stepper class="stepper" v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1">
          Kontaktdaten
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2">
          Ihr Material
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3"> Absenden </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-form v-model="valid">
            <v-card elevation="0" class="contact-card">
              <div class="text-body-1 hint">
                Wer möchte das Angebot erhalten?
              </div>
              <v-row>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="salutation"
                    :items="['Herr', 'Frau', 'Firma']"
                    label="Anrede"
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="company"
                    :rules="nameRules"
                    label="Firma"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="firstname"
                    :rules="nameRules"
                    label="Vorname"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="lastname"
                    :rules="nameRules"
                    label="Nachname"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="E-Mail"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card>
          </v-form>

          <div class="buttons">
            <v-btn color="primary" @click="e1 = 2"> Weiter </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="mb-12" color="grey lighten-1" height="200px"></v-card>

          <div class="buttons">
            <v-btn color="primary" @click="e1 = 3"> Weiter </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card class="mb-12" color="grey lighten-1" height="200px"></v-card>

          <div class="buttons">
            <v-btn color="primary" @click="e1 = 1"> Weiter </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
export default {
  data() {
    return {
      e1: 1,
    };
  },
};
</script>

<style>
.contact-card {
  padding: 1rem;
  max-width: 70%;
}

.buttons {
  margin-top: 2rem;
  text-align: right;
}

.stepper {
  margin: 2rem;
}

.back {
  padding-top: 0.5rem;
}

.hint {
  margin-bottom: 1rem;
}
</style>