<template>
  <v-card>
    <div class="top-buttons">
      <v-btn @click="$emit('toggleDialog')" icon>
        <v-icon large secondary>mdi-close</v-icon></v-btn
      >
    </div>
    <v-card-title class="text-h5 grey lighten-2">
      <span class="dialog-title">Nachricht senden</span><v-spacer></v-spacer>
    </v-card-title>
    <v-container v-if="!isSubmitted">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              label="Ihre E-Mail Adresse"
              required
              v-model="email"
              :rules="emailRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-textarea
              solo
              label="Ihre Nachricht"
              required
              v-model="message"
              :rules="messageRules"
            ></v-textarea>
            <v-switch
              label="Ich bin kein Roboter..."
              required
              v-model="noRobot"
            ></v-switch>
            <div v-if="showRobotMsg" class="text-caption text-red">
              Bitte bestätigen Sie, dass Sie kein Roboter sind!
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-container v-else>
      <v-row>
        <v-col align="center">
          <v-icon x-large>mdi-check</v-icon><span>Erfolgreich versendet!</span>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions v-if="!isSubmitted">
      <v-spacer></v-spacer>
      <v-btn text @click="close"> Abbrechen </v-btn>
      <v-btn text @click="submit">Absenden</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import emailjs from "emailjs-com";
export default {
  data() {
    return {
      email: "",
      message: "",
      noRobot: false,
      showRobotMsg: false,
      isSubmitted: false,
      valid: false,
      emailRules: [
        (v) =>
          /.+@.+\..+/.test(v) ||
          "Bitte geben Sie eine valide E-Mail Adresse ein!",
      ],
      messageRules: [(v) => !!v || "Bitte geben Sie eine Nachricht ein!"],
    };
  },
  methods: {
    async submit() {
      if (!this.noRobot) {
        this.$refs.form.validate();
        this.showRobotMsg = true;
        return;
      } else {
        this.showRobotMsg = false;
      }
      if (this.$refs.form.validate()) {
        try {
          await emailjs.send(
            "service_nd1j22g",
            "template_fs11p85",
            {
              email: this.email,
              message: this.message,
            },
            "user_ze6gbCqA9qvi8AKrPlqNe"
          );
          this.isSubmitted = true;

          setTimeout(
            function () {
              this.close();
            }.bind(this),
            3000
          );
        } catch (error) {
          this.isSubmitted = false;
          console.log({ error });
        }
      }
    },
    close() {
      this.isSubmitted = false;
      this.$nextTick(() => {
        this.$refs.form.resetValidation();
        this.$refs.form.reset();
      });

      this.showRobotMsg = false;
      this.$emit("toggleDialog");
    },
  },
};
</script>
