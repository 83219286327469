import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

import de from 'vuetify/lib/locale/de'

export default new Vuetify({
    theme: {
        themes: {
            light: {
                // primary: '#C5CFDA',
                primary: '#052440',
                secondary: '#C5CFDA',
                accent: '#052440',
                error: '#B00020',
            },
        },
    },
    lang: {
        locales: { de },
        current: 'de',
    }
});
