<template>
  <div style="height: 100%">
    <iframe
      class="frame"
      src="https://maps.google.com/maps?q=Foto%20Nova%20GmbH&t=&z=13&ie=UTF8&iwloc=&output=embed"
    ></iframe>
  </div>
</template>

<script>
export default {};
</script>

<style>
.frame {
  width: 100%;
  height: 100%;
}
</style>