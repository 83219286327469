<template>
  <v-card class="card" max-width="300">
    <v-img contain class="img white--text align-end" height="200px" :src="img">
      <v-card-title style="background-color: #00000073">{{
        title
      }}</v-card-title>
    </v-img>

    <v-card-text class="text text--primary">
      <div>
        {{ text }}
      </div>
    </v-card-text>

    <v-card-actions class="actions">
      <router-link v-if="route" :to="route" class="button-link">
        <v-btn text>Mehr Erfahren...</v-btn></router-link
      >
      <router-link v-else to="" class="button-link">
        <v-btn text class="not-allowed"
          >Erfahren Sie bald mehr...</v-btn
        ></router-link
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: { img: String, title: String, text: String, route: String },
};
</script>

<style scoped>
.button-link {
  text-decoration: none;
}
.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.card {
  display: flex;
  flex-direction: column;
}

.img {
  flex-grow: 0;
}

.text {
  flex-grow: 1;
}

.not-allowed {
  cursor: not-allowed;
}
</style>