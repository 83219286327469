<template>
  <v-row justify="center">
    <div class="card-list">
      <service-card
        v-for="service in localServices"
        :key="service.id"
        :title="service.label"
        :text="service.text"
        :route="service.route"
        :img="service.img_url"
      ></service-card>
    </div>
  </v-row>
</template>

<script>
import ServiceCard from "./ServiceCard.vue";
import Services from "../../data/services";
import Settings from "../../data/settings";

export default {
  components: { ServiceCard },
  data() {
    return {
      services: Services,
    };
  },
  computed: {
    localServices() {
      let array = this.services;
      if (Settings?.shuffleService) {
        for (
          var j, x, i = array.length;
          i;
          j = parseInt(Math.random() * i),
            x = array[--i],
            array[i] = array[j],
            array[j] = x
        );
      }
      return array;
    },
  },
};
</script>

<style>
.card-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content:center;
  padding: 0rem 2rem 2rem 2rem;
}
</style>