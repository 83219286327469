<template>
  <div class="home">
    <head-menu class="head-menu"></head-menu>
    <v-card>
      <v-img src="img/frontpage/studio.jpg" max-height="450">
        <div class="text-center" cols="12">
          <h1 class="text-h4 font-weight-thin mb-4">Willkommen!</h1>
          <h4 class="subheading">Digitalisierung | Fotografie | Bearbeitung | Verkauf</h4>
        </div>
      </v-img>
    </v-card>
    <services class="services"></services>
    
  </div>
</template>

<script>
import HeadMenu from "../components/menus/HeadMenu.vue";
import Services from "../components/services/Services.vue";

export default {
  name: "Home",
  components: { Services, HeadMenu },
};
</script>
<style scoped>
.home {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  align-items: center;
}

.text-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #00000073;
  color: white;
  margin-top: 10%;
  height: 120px;
  /* #05244073; */
}

.services {
  padding-top: 1.5rem;
  max-width: 1080px;
}

.paralax {
  width: 100%;
}

.head-menu {
  width: 100%;
}
</style>
