<template>
  <div>
    <v-app-bar height="80px" app color="secondary">
      <router-link to="/">
        <v-img
          contain
          max-width="17rem"
          :src="require('../../assets/logos/logo.png')"
        ></v-img>
      </router-link>
      <v-spacer></v-spacer>
      <div class="icons-hidden-small">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              elevation="0"
              @click="$emit('toggleDialog', 'dialog-opening-hours')"
              ><v-icon>mdi-clock</v-icon></v-btn
            >
          </template>
          <span>Öffnungszeiten</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              elevation="0"
              v-bind="attrs"
              v-on="on"
              @click="$emit('toggleDialog', 'dialog-contact-formular')"
              ><v-icon>mdi-email</v-icon></v-btn
            >
          </template>
          <span>Nachricht senden</span>
        </v-tooltip>
        <!-- <v-btn
          icon
          elevation="0"
          @click="$emit('toggleDialog', 'dialog-whatsapp')"
          ><v-icon>mdi-whatsapp</v-icon></v-btn
        > -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              elevation="0"
              v-bind="attrs"
              v-on="on"
              @click="$emit('toggleDialog', 'dialog-telephone')"
              ><v-icon>mdi-phone</v-icon></v-btn
            >
          </template>
          <span>Rufen Sie an!</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" @click="openMap" icon
              ><v-icon>mdi-google-maps</v-icon></v-btn
            >
          </template>
          <span>Anfahrt</span>
        </v-tooltip>
      </div>
      <v-app-bar-nav-icon
        @click="$emit('toggleDrawer')"
        class="nav-icon"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <!-- <v-btn
      href="https://wa.me/490704215777"
      class="fab wa"
      elevation="2"
      color="#25D366"
      fab
      ><v-icon large color="white">mdi-whatsapp</v-icon></v-btn
    > -->
    <v-btn
      href="tel:0049704215777"
      class="fab tel"
      elevation="2"
      color="primary"
      fab
      ><v-icon large color="white">mdi-phone</v-icon></v-btn
    >
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    openMap() {
      this.$router.replace("/contactmap");
    },
  },
};
</script>

<style scoped>
.tel {
  bottom: 200px;
}

.wa {
  bottom: 64px;
}

@media (max-width: 600px) {
  .icons-hidden-small {
    display: none;
  }

  .fab {
    right: 35px;
    position: fixed;
    z-index: 2;
  }
}

@media (min-width: 601px) {
  .icons-hidden-small {
    display: flex;
  }

  .nav-icon {
    display: none;
  }
  .fab {
    display: none;
  }
}
</style>